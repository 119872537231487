import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from "@heroicons/react/solid";

export default function ButtonWithDropdown({ item, items }) {
  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        type="button"
        onClick={item.action}
        className="flex items-center gap-2 py-1 pl-1 pr-2 text-xs transition-all border-t-2 border-b-2 border-l-2 border-r rounded-l-md lg:pr-5 lg:pl-3 lg:py-2 text-platform border-platform hover:bg-white"
      >
        {item.icon || null}
        {item.name}
      </button>
      <Menu as="div" className="relative block">
        <Menu.Button
          className="relative inline-flex items-center border-t-2 border-b-2 border-l border-r-2 rounded-r-md px-2 py-2 text-platform border-platform hover:bg-white focus:z-10"
        >
          <span className="sr-only">Open options</span>
          <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
        </Menu.Button>
        <Menu.Items
          transition
          className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {items.map((item) => (
              <Menu.Item key={item.name}>
                <a
                  onClick={item.action}
                  className="hover:bg-platformlight hover:text-gray-900 cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                  {item.name}
                </a>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  )
}
