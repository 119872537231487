import React from "react";

import ActionButton from "./fields/ActionButton";
import ButtonWithDropdown from "./fields/ButtonWithDropdown";
import NumberDisplay from "./fields/NumberDisplay";
import QBOSettings from "./QBOSettings";
import XeroSettings from "./XeroSettings";
import {
  ArrowLeftIcon,
  BookmarkIcon,
  LightningBoltIcon,
  ArrowRightIcon,
  RefreshIcon
} from "@heroicons/react/outline";


class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoading: true,
      loading: true,
      connected: false,
      propertyStatus: false,
      propertySuccess: false,
      propertyError: false,
      propertyLoading: false,
      error: false,
      minimised: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount = async () => {
    
    let api = process.env.REACT_APP_API_ROOT;
    let accessToken = new URLSearchParams(document.location.search).get("access_token");

    try {
      let connectionSettings = await fetch(api + "/connections/data/get/" + this.props.connectionData.connectionId, {
        headers: {
          "Content-Type": "application/json",
          accesstoken: accessToken,
        },
      }).then((res) => res.json());

      if(connectionSettings.error) {
        throw connectionSettings.error
      }
      this.setState({ connectionSettings });

    } catch(error) {
      console.log(error);
      if(error !== "JsonWebTokenError") {
        this.props.setError(
          "Connection Error",
          true,
          `Invoice Stack couldn\'t connect to your accounting platform, please reconnect it via <a class="underline text-primary" href="${process.env.REACT_APP_LINK}/accounting" target="_blank">your dashboard</a>. <br /> <br /><a class="underline text-primary" href="${process.env.REACT_APP_RECONNECT_XERO_ACCOUNT_LINK}" target="_blank">View support article</a> for more help and a walkthrough.`
        );
      }
    }

    window.addEventListener('scroll', this.handleScroll);
    this.scrollTrigger = document.querySelectorAll(".ghost")[0];
    this.syncContainer = document.querySelectorAll(".sync-container")[0];
  };

  componentWillUnmount = async () => {
    window.removeEventListener('scroll', this.handleScroll);
  };
  
  handleScroll(event) {
    let top = this.scrollTrigger.getBoundingClientRect().top;
    this.setState({ minimised: top < this.syncContainer.offsetHeight + 24  }); // 24 is magic number relating to px-5
  }

  allEditableTaxesAreCustom() {
    const filteredArray = this.props.invoices
      .filter(invoice => {
        const isFromRepeatingInvoice = invoice?.repeating_invoice_id && this.props.invoices.find(i => i.external_id === invoice.repeating_invoice_id);
        const hasUneditableStatus = ["AUTHORISED", "PAID", "SENT", "VOIDED", "DELETED"].includes(invoice.status);
        const isXeroRepeating = invoice?.external_id && invoice.repeating && this.props.connectionData.connectionType === "xero";
        
        return !isFromRepeatingInvoice && !hasUneditableStatus && !isXeroRepeating;
      });

    if (filteredArray.length === 0) {
      return false;
    }

    return filteredArray.every(invoice => invoice.customTaxes);
  }

  hasEditableTax = () => {
    const invoices = this.props.invoices;

    const filteredArray = invoices
      .filter(invoice => {
        const isFromRepeatingInvoice = invoice?.repeating_invoice_id && invoices.find(i => i.external_id === invoice.repeating_invoice_id);
        const hasUneditableStatus = ["AUTHORISED", "PAID", "SENT", "VOIDED", "DELETED"].includes(invoice.status);
        return !isFromRepeatingInvoice && !hasUneditableStatus;
      });
  
    return !!filteredArray.find(invoice =>
      invoice.line_items.some(item => item.taxType === 'TAX')
    );
  }

  render() {
    return (
      <>
        <div
          className={
            (this.state.minimised ? "fixed border-b bg-opacity-80	" : "absolute ") +
            " top-0 z-30 flex w-full px-4 py-2 sidebar bg-gray-50 gap-2 justify-end "
          }
        >
          <div className={(this.state.minimised ? "hidden " : "") + "flex-1"}>
            {this.props.connectionData.connectionType === "xero" &&
            this.props.fetchedInvoices &&
            this.props?.preferences &&
            this.state.connectionSettings ? (
              <XeroSettings
                connectionSettings={this.state.connectionSettings}
                updateSetting={this.props.updateSetting}
                hubspotData={this.props.hubspotData}
                connectionData={this.props.connectionData}
                preferences={this.props.preferences}
                settings={this.props.deal.settings}
                globalData={this.props.globalData}
                setError={this.props.setError}
                setSuccess={this.props.setSuccess}
                showEditContact={this.props.showEditContact}
                toggleEditContact={this.props.toggleEditContact}
                platformStyles={this.props.platformStyles}
                allEditableTaxesAreCustom={this.allEditableTaxesAreCustom()}
                getTaxFromTaxType={this.props.getTaxFromTaxType}
              />
            ) : null}

            {this.props.connectionData.connectionType === "qbo" &&
            this.props.fetchedInvoices &&
            this.props?.preferences &&
            this.state.connectionSettings ? (
              <QBOSettings
                connectionSettings={this.state.connectionSettings}
                updateSetting={this.props.updateSetting}
                hubspotData={this.props.hubspotData}
                connectionData={this.props.connectionData}
                preferences={this.props.preferences}
                settings={this.props.deal.settings}
                globalData={this.props.globalData}
                setError={this.props.setError}
                setSuccess={this.props.setSuccess}
                showEditContact={this.props.showEditContact}
                toggleEditContact={this.props.toggleEditContact}
                platformStyles={this.props.platformStyles}
                hasEditableTax={this.hasEditableTax()}
                allEditableTaxesAreCustom={this.allEditableTaxesAreCustom()}
                getTaxFromTaxType={this.props.getTaxFromTaxType}
              />
            ) : null}
          </div>
          <div className="flex-shrink flex-rows min-w-max">
            <div className={(this.state.minimised ? "hidden" : "") + " flex"}>
              <label className="flex items-center w-full gap-2 text-right">
                <span className="flex-1 text-xs text-right text-gray-700">
                  {this.props.deal.projectedTotal !== this.props.deal.invoiceTotal
                    ? "Projected Total"
                    : "Invoices Total"}
                </span>
                <span className="flex-shrink text-2xl font-semibold">
                  {this.props.deal.settings.currency !== "" ? (
                    <NumberDisplay
                      value={
                        this.props.deal.projectedTotal !== this.props.deal.invoiceTotal
                          ? this.props.deal.projectedTotal
                          : this.props.deal.invoiceTotal
                      }
                      currency={this.props.deal.settings.currency}
                    />
                  ) : (
                    <>&nbsp;</>
                  )}
                </span>

                <div className="flex items-center justify-start gap-1 text-xs cursor-help ">
                  {(this.props.deal.projectedTotal !== this.props.deal.invoiceTotal
                    ? this.props.deal.projectedTotal
                    : this.props.deal.invoiceTotal) === parseFloat(this.props.deal.total) ? (
                    <div className="relative flex group">
                      <span className="inline-flex w-3 h-3 bg-green-600 rounded-full"></span>
                      <span className="absolute overflow-visible opacity-0 transition-all px-3 py-1 text-xs text-right bg-black text-white rounded-md shadow-sm bottom-100% -right-2 mt-5 whitespace-nowrap group-hover:opacity-100">
                        Invoices match deal total
                        <span className="absolute top-0 right-2 -mt-1.5 w-0 h-0 border-l-[.5rem] border-l-transparent border-b-[.75rem] border-b-black border-r-[.5rem] border-r-transparent"></span>
                      </span>
                    </div>
                  ) : (
                    <div className="relative group">
                      <span className="inline-flex w-3 h-3 bg-red-600 rounded-full"></span>
                      <span className="absolute overflow-visible opacity-0 transition-all px-3 py-1 text-xs text-right bg-black text-white rounded-md shadow-sm bottom-100% -right-2 mt-5 whitespace-nowrap group-hover:opacity-100 flex gap-1">
                        <NumberDisplay
                          value={Math.abs(
                            (this.props.deal.projectedTotal !== this.props.deal.invoiceTotal
                              ? this.props.deal.projectedTotal
                              : this.props.deal.invoiceTotal) - this.props.deal.total
                          )}
                          currency={this.props.deal.settings.currency}
                          className="text-white"
                        />
                        {(this.props.deal.projectedTotal !== this.props.deal.invoiceTotal
                          ? this.props.deal.projectedTotal
                          : this.props.deal.invoiceTotal) -
                          this.props.deal.total >
                        0
                          ? " over deal total"
                          : " under deal total"}
                        <span className="absolute top-0 right-2 -mt-1.5 w-0 h-0 border-l-[.5rem] border-l-transparent border-b-[.75rem] border-b-black border-r-[.5rem] border-r-transparent"></span>
                      </span>
                    </div>
                  )}
                </div>
              </label>
            </div>

            <div className="flex items-end justify-end gap-2 sync-container">
              {/* <label><span className="text-xs">{this.props.changed ? 'Unsaved changes' : ''  }</span></label> */}

              <div className="flex flex-col gap-1 lg:gap-2 lg:flex-row">
                {!this.props.preferences?.recurringBeta ? (
                  <ActionButton
                    action={(e) => {
                      this.props.updateSetting("mode", "");
                    }}
                    className="px-2 py-1 text-xs border-2 rounded-md lg:px-5 lg:py-2 text-platform border-platform"
                    text="Settings"
                  />
                ) : (
                  this.props.preferences?.syncLineItemsWithHubspot ? 
                  <button
                    onClick={(e) => { this.props.autoFill(false) }}
                    className="flex items-center gap-2 py-1 pl-1 pr-2 text-xs transition-all border-2 rounded-md lg:pr-5 lg:pl-3 lg:py-2 text-platform border-platform hover:bg-white"
                  >
                    <LightningBoltIcon className="w-5 h-5" />
                    AutoFill
                  </button> :
                  <ButtonWithDropdown
                    item={{ name: "AutoFill", action: (e) => { this.props.autoFill(true) }, icon: <LightningBoltIcon className="w-5 h-5" /> }}
                    items={[{ name: "AutoFill everything", action: (e) => { this.props.autoFill(false) } }]}
                  />
                )}
                <button
                  onClick={(e) => this.props.saveInvoices()}
                  className="flex items-center gap-2 py-1 pl-1 pr-2 text-xs transition-all border-2 rounded-md lg:pr-5 lg:pl-3 lg:py-2 text-platform border-platform hover:bg-white"
                >
                  <BookmarkIcon className="w-5 h-5" />
                  Save Progress
                </button>
              </div>
              <button
                onClick={(e) => this.props.syncInvoices()}
                className="flex items-center gap-2 px-6 py-3 text-white transition-all border-2 rounded-md border-platform bg-platform hover:bg-opacity-90"
              >
                <ArrowRightIcon className="w-5 h-5" />
                {this.props.connectionData.connectionType === "xero"
                  ? "Sync to Xero"
                  : "Sync to QuickBooks"}
              </button>
            </div>
          </div>
        </div>
        <div className="ghost"></div>
      </>
    );
  }
}

export default Sidebar;
