import React from "react";
import AsyncSelect from "react-select/async";

import asyncDebounce from "../utils/debounceAsync";

let api = process.env.REACT_APP_API_ROOT;

class ProductField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  onChange(event) {
    this.props.onChange(event.target.value);
  }

  componentDidMount = async () => {
    if (this.props.sku && !this.props.reference) {
      let searchResults = await this.doMatch(this.props.sku);
      if (searchResults.products.length === 1) {
        await this.selectProduct(searchResults.products[0],'add');
      }
    }
    this.setState({ isLoading: false });
  };

  async doMatch(sku) {
    let accessToken = new URLSearchParams(document.location.search).get("access_token");
    // TODO - perhaps this would be better to search by SKU or Name? How Do we do that in QBO?
    const matchResults = await fetch(api + "/products/match/" + encodeURIComponent(sku), {
      headers: {
        "Content-Type": "application/json",
        accesstoken: accessToken,
      },
    }).then((res) => res.json());

    return matchResults;
  }

  doSearch = asyncDebounce(async (query) => {
    if (query.length < 3) {
      return [];
    }

    let accessToken = new URLSearchParams(document.location.search).get("access_token");
    const searchResults = await fetch(api + "/products/search/" + encodeURIComponent(query), {
      headers: {
        "Content-Type": "application/json",
        accesstoken: accessToken,
      },
    }).then((res) => res.json());
    return searchResults;
  });

  loadOptions = (query) =>
    new Promise(async (resolve) => {
      let results = await this.doSearch(query);
      resolve(results.products);
  });

  selectProduct(product, actionType) {
    const { connectionType, country } = this.props?.connectionData;
    const isQboUs = connectionType === "qbo" && country === "US";

    if (actionType.action === "clear") {
      this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "sku", "");
      this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "reference", "");
      this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "itemLabel", "");
      this.setState({ isSearching: true });
    } else {
      this.props.updateItem(
        this.props.invoiceIndex,
        this.props.itemIndex,
        "reference",
        product.value
      );
      this.props.updateItem(this.props.invoiceIndex, this.props.itemIndex, "sku", product.sku);
      this.props.updateItem(
        this.props.invoiceIndex,
        this.props.itemIndex,
        "itemLabel",
        product.label
      );
      if(product?.taxable) {
        this.props.updateItem(
          this.props.invoiceIndex,
          this.props.itemIndex,
          "taxable",
          product.taxable
        );

        if (isQboUs) {
          this.props.updateItem(
            this.props.invoiceIndex,
            this.props.itemIndex,
            "taxType",
            "TAX"
          );
        }
      }

      if (product?.taxType && !this.props.settings?.contactHasCustomTax) {
        this.props.setCustomTaxes(true);
        this.props.updateTax(product.taxType);
      }
      if (this.props.settings?.contactHasCustomTax && !isQboUs) {
        this.props.setCustomTaxes(true);
        this.props.updateTax(this.props.settings.contactHasCustomTax);
      }
      if (product?.accountCode) {
        this.props.updateItem(
          this.props.invoiceIndex,
          this.props.itemIndex,
          "account",
          product.accountCode
        );

        if (connectionType === "xero") {
          this.props.setCustomTaxes(true);
        }
      }
      if (product?.class) {
        this.props.updateItem(
          this.props.invoiceIndex,
          this.props.itemIndex,
          "class",
          product.class
        );
      }
      // Only do these things if empty...
      //this.props.updateItem(this.props.invoiceIndex,this.props.itemIndex,'description',product.label);
      if (this.props.price === 0 && product?.price) {
        this.props.updateItem(
          this.props.invoiceIndex,
          this.props.itemIndex,
          "price",
          product.price
        );
      }
      if (!this.props.description || this.props.description === "") {
        this.props.updateItem(
          this.props.invoiceIndex,
          this.props.itemIndex,
          "description",
          product.label
        );
      }
    }
  }
  componentDidUpdate() {}
  render() {
    return (
      <div className="flex-1">
        <AsyncSelect
          loadingMessage={(e) =>
            e.inputValue.length < 3 ? "Type at least 3 characters" : "Searching"
          }
          noOptionsMessage={(e) =>
            e.inputValue.length < 3 ? "Type at least 3 characters" : "Nothing found"
          }
          placeholder="Add Item"
          styles={{
            control: (provided, state) => ({
              ...provided,
              border: 0,
              borderBottom: 0,
              boxShadow: 0,
            }),
            indicatorSeparator: (provided, state) => ({
              ...provided,
              width: 0,
            }),
            menu: (provided, state) => ({
              ...provided,
              marginTop: 1,
            }),
          }}
          defaultOptions={this.props.products}
          cacheOptions={true}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            border: 0,
            colors: {
              ...theme.colors,
              primary25: "#cceffa",
              primary50: "#cceffa",
              primary: "#0078c8",
            },
            spacing: {
              ...theme.spacing,
              baseUnit: 2,
            },
          })}
          getOptionLabel={(option) => {
            return option.sku ? option.sku + " " + option.label : option.label;
          }}
          isClearable
          value={
            this.props.reference
              ? { value: this.props.reference, label: this.props.itemLabel, sku: this.props.sku }
              : false
          }
          openMenuOnFocus="true"
          isLoading={this.state.isLoading}
          loadOptions={this.loadOptions}
          onChange={this.selectProduct.bind(this)}
        />
      </div>
    );
  }
}

export default ProductField;
