import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { DateTime } from "luxon";

import Invoice from "./Invoice";
import RepeatingInvoice from "./RepeatingInvoice";
import NumberPicker from "./fields/NumberPicker";
import ImportInvoice from './modals/ImportInvoice';
import { PlusSmIcon } from "@heroicons/react/solid";

class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      repeating: false,
      showImportInvoice: false
    };
  }
  addItem(invoiceIndex, data) {
    const { country, connectionType } = this.props.connectionData;
    const isQboUs = country === "US" && connectionType === "qbo";

    let invoices = this.props.invoices;

    const lineData = {
      key: this.generateRandomKey(),
      description: data?.description || "",
      qty: data?.qty || 1,
      price: data?.subtotal && data?.qty ? (data.subtotal / data.qty) : 0,
      subtotal: data?.subtotal || 0,
      tax: data?.tax || 0,
      taxType: data?.taxType || (isQboUs ? 'NON' : false),
      account: data?.account || "NONE",
      sku: data?.sku || undefined,
      taxable: data?.taxable || undefined,
      serviceDate: data?.serviceDate || undefined,
      reference: data?.reference || undefined,
      itemLabel: data?.itemLabel || undefined,
      sourceHubspotId: data?.sourceHubspotId || undefined,
    }

    const trackingData = {};
    if (data?.tracking) {
      for (const cat in data.tracking) {
        trackingData[cat] = data.tracking[cat];
      }
    }
    lineData.tracking = trackingData;
    
    invoices[invoiceIndex].line_items.push(lineData);
    this.props.setInvoiceState(invoices);
  }

  cloneItem(invoiceIndex, itemIndex) {
    let invoices = this.props.invoices;
    let item = invoices[invoiceIndex].line_items[itemIndex];
    this.addItem(invoiceIndex, item);
  }

  generateRandomKey() {
    return Math.random().toString(36).substring(7);
  }

  removeItem(invoiceIndex, itemIndex) {
    let invoices = this.props.invoices;
    let lineItems = invoices[invoiceIndex].line_items;
    let newLineItems = lineItems.filter((item, index) => {
      if (index !== itemIndex) {
        return item;
      }
    });
    invoices[invoiceIndex].line_items = newLineItems;
    this.props.setInvoiceState(invoices);
  }

  updateItem(invoiceIndex, itemIndex, type, val) {
    let invoices = this.props.invoices;
    let item = invoices[invoiceIndex].line_items[itemIndex];
    item.error = false;
    item.errorDetails = null;

    item[type] = val;

    if (type === "discount") {
      if (typeof val === "string" && val.includes("%")) {
        item.discountRate = val.split("%")[0];
        if (this.countDecimals(item.discountRate) > 2) {
          item.error = "discount";
          item.errorDetails = "Up to 2 decimal places allowed on Discount";
        }
        delete item.discountAmount;
      } else {
        item.discountAmount = val;

        if (this.countDecimals(item.discountAmount) > 2) {
          item.error = "discount";
          item.errorDetails = "Up to 2 decimal places allowed on Discount";
        }
        delete item.discountRate;
      }
    }

    if (type === "qty") {
      if (item.qty === "") {
        item.qty = 0;
      }
      if (this.countDecimals(item.qty) > 4) {
        item.error = "qty";
        item.errorDetails = "Up to 4 decimal places allowed on Quantity";
      }
    }

    if (type === "price" || type === "discount" || type === "qty") {
      if (item.discountAmount) {
        item.subtotal = item.price * item.qty - item.discountAmount;
      } else if (item.discountRate) {
        let discountDecimal = (100 - item.discountRate) / 100
        item["subtotal"] = item.price * item.qty * discountDecimal;
      } else {
        item["subtotal"] = item.price * item.qty;
      }
      item["subtotal"] = this.round(item["subtotal"],2);
    }

    if (type === "subtotal") {
      if (
        item.discountAmount &&
        this.round(item.subtotal, 2) !== this.round(item.price * item.qty - item.discountAmount, 2)
      ) {
        item.error = "subtotal";
        item.errorDetails = "Line subtotal does not match";
      } else if (
        item.discountRate &&
        this.round(item.subtotal, 2) !==
          this.round(item.price * item.qty - (item.price * item.qty * item.discountRate) / 100, 2)
      ) {
        item.error = "subtotal";
        item.errorDetails = "Line subtotal does not match";
      } else if (
        !item.discountAmount &&
        !item.discountRate &&
        item.subtotal !== item.price * item.qty
      ) {
        item.error = "subtotal";
        item.errorDetails = "Line subtotal does not match";
      }
    }
    if (item.discountRate > 100) {
      item.error = "discount";
      item.errorDetails = "Discount cannot be over 100%";
    }
    if (item.discountAmount > 0 && item.discountAmount > this.round(item.price * item.qty, 2)) {
      item.error = "discount";
      item.errorDetails = "Discount cannot be greater than subtotal";
    }
    if (item.discountAmount < 0) {
      item.error = "discount";
      item.errorDetails = "Discount cannot be less than 0";
    }
    if (item.qty < 0) {
      item.error = "qty";
      item.errorDetails = "Quantity cannot be less than 0";
    }

    if (type === "subtotal" && item.qty === 1) {
      item.price = val;
    }
    if (
      this.props.preferences.productsEnabled &&
      !item.reference &&
      item.price >= 0
    ) {
      item.error = "reference";
      item.errorDetails = "Select an item";
    }
    if(this.props.hubspotData.connectionType === "qbo" && this.props.preferences.country !== "US" && (!item.taxType || item.taxType === "NONE")) {
      item.error = "tax";
      item.errorDetails = "Select a tax rate"
    }
    invoices[invoiceIndex].line_items[itemIndex] = item;
    this.props.setInvoiceState(invoices);
  }

  resetAccounts(invoiceIndex) {
    const connectionType = this.props?.connectionData?.connectionType;
    const country = this.props?.preferences?.country;
    const isQboUs = connectionType === "qbo" && country === "US";

    const invoices = this.props.invoices;

    if (isQboUs) {
      const invoice = invoices[invoiceIndex];
      invoice.taxType = invoice?.prevTaxType ? invoice.prevTaxType : "TAX";
      if (invoice.taxType === "TAX") {
        // taxType "TAX" means it will be autocalculated by QBO, using their AST engine.
        // Therefore we cannot know or calculate the totalTax, so set it to 0.
        invoice.totalTax = 0;
      }
    } else {
      for (const [index, item] of invoices[invoiceIndex].line_items.entries()) {
        item.account = item?.prevAccount ? item.prevAccount : "NONE";
        item.taxType = item?.prevTaxType ? item.prevTaxType : false;
        item.tax = item?.prevTax ? item.prevTax : 0;
        invoices[invoiceIndex].line_items[index] = item;
      }
    }

    this.props.setInvoiceState(invoices);
  }

  setTaxAndAccountsToDealSettings(invoiceIndex) {
    const connectionType = this.props?.connectionData?.connectionType;
    const country = this.props?.preferences?.country;
    const isQboUs = connectionType === "qbo" && country === "US";

    const invoices = this.props.invoices;

    if (isQboUs) {
      const invoice = invoices[invoiceIndex];
      invoice.prevTaxType = invoice.taxType;
      invoice.taxType = this.props.deal.settings?.taxType || "TAX";
      if (invoice.taxType === "TAX") {
        // taxType "TAX" means it will be autocalculated by QBO, using their AST engine.
        // Therefore we cannot know or calculate the totalTax, so set it to 0.
        invoice.totalTax = 0;
      }
    } else {
      for (const [index, item] of invoices[invoiceIndex].line_items.entries()) {
        item.prevAccount = item.account;
        item.prevTax = item.tax;
        item.prevTaxType = item.taxType;
  
        item.account = this.props.deal.settings?.account || "NONE";
        item.tax = this.props.deal.settings?.tax || 0;
        item.taxType = this.props.deal.settings?.taxType || false;
  
        invoices[invoiceIndex].line_items[index] = item;
      }
    }

    this.props.setInvoiceState(invoices);
  }

  onDragEnd(result) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      this.reorderItems(source.droppableId, source.index, destination.index);
    } else {
      this.moveItem(source.droppableId, destination.droppableId, source, destination);
    }
  }

  reorderItems(invoiceKey, startIndex, endIndex) {
    const result = this.props.invoices.find((x) => x.key === invoiceKey);
    const [removed] = result.line_items.splice(startIndex, 1);
    result.line_items.splice(endIndex, 0, removed);

    let invoices = this.props.invoices;
    let invoiceIndex = invoices.findIndex((x) => x.key === invoiceKey);

    invoices[invoiceIndex] = result;
    this.props.setInvoiceState(invoices);
  }

  moveItem(source, destination, droppableSource, droppableDestination) {
    let invoices = this.props.invoices;

    const sourceClone = invoices.find((x) => x.key === source);
    const sourceIndex = invoices.findIndex((x) => x.key === source);
    const destinationClone = invoices.find((x) => x.key === destination);
    const destinationIndex = invoices.findIndex((x) => x.key === destination);

    const [removed] = sourceClone.line_items.splice(droppableSource.index, 1);
    destinationClone.line_items.splice(droppableDestination.index, 0, removed);

    invoices[sourceIndex] = sourceClone;
    invoices[destinationIndex] = destinationClone;
    this.props.setInvoiceState(invoices);
  }

  countDecimals(value) {
    value = parseFloat(value);
    if (isNaN(value) ) {
      return 0;
    }
    if (Math.floor(value) !== value) return value.toString().split(".")[1].length || 0;
    return 0;
  }

  round(value, decimals) {
    value = parseFloat(value);
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  }

  getStandardInvoices() {
    const repeating = this.props.invoices.reduce((acc, invoice) => {
      if (invoice.repeating && invoice.external_id) {
        acc.push(invoice.external_id);
      }
    
      return acc;
    }, []);

    return this.props.invoices.filter( invoice => {
      if (invoice.repeating) {
        return false;
      }

      if (!invoice.repeating_invoice_id) {
        return true;
      }

      // If the repeating invoice is not in Invoice Stack,
      // treat this invoice as if it is a standard invoice.
      return !repeating.includes(invoice.repeating_invoice_id)
    });
  }

  getLinkedInvoices(invoice) {
    const linkedInvoices = this.props.invoices.filter((inv) => {
      return invoice.external_id && inv.repeating_invoice_id === invoice.external_id;
    });

    linkedInvoices.sort(function (a, b) {
      return new DateTime.fromISO(b.date) - new DateTime.fromISO(a.date);
    });

    return linkedInvoices;
  }

  getInvoice(invoiceId) {
    const invoice = this.props.invoices.find(
      inv => invoiceId === inv.id
    );

    invoice.linked_invoices = this.getLinkedInvoices(invoice);

    if (invoice.previous_id) {
      invoice.previous_invoice = this.getInvoice(invoice.previous_id);
    }

    return invoice;
  }

  getRepeatingInvoices() {
    const replaced = this.props.invoices.reduce((acc, invoice) => {
      if (invoice.previous_id) {
        acc.push(invoice.previous_id);
      }
    
      return acc;
    }, []);

    const repeatingInvoices = this.props.invoices.filter(invoice => 
      invoice.repeating && !replaced.includes(invoice.id)
    );
    
    const grouped = repeatingInvoices.map(invoice => {
      invoice.linked_invoices = this.getLinkedInvoices(invoice);

      if (invoice.previous_id) {
        invoice.previous_invoice = this.getInvoice(invoice.previous_id);
      }

      return invoice;
    });

    return grouped;
  }

  render() {
    const standardInvoices = this.getStandardInvoices();
    const repeatingInvoices = this.getRepeatingInvoices();

    const invoices = [...standardInvoices, ...repeatingInvoices];

    const invoiceNode = invoices.map((invoice) => {
      const index = this.props.invoices.findIndex(inv => inv.key === invoice.key);

      if (invoice.repeating) {
        const error = this.props.invoiceErrors.find(e => e.invoice.repeating && e.invoice.id === invoice.id);

        return <RepeatingInvoice
          invoice={invoice}
          error={error || null}
          deal={this.props.deal}
          preferences={this.props.preferences}
          connectionData={this.props.connectionData}
          shortCode={this.props.connectionData?.shortCode}
          key={invoice.key}
          invoiceIndex={index}
          updateInvoice={this.props.updateInvoice}
          cloneInvoice={this.props.cloneInvoice}
          removeInvoice={this.props.removeInvoice}
          updateSetting={this.props.updateSetting}
          cloneItem={this.cloneItem.bind(this)}
          updateItem={this.updateItem.bind(this)}
          addItem={this.addItem.bind(this)}
          removeItem={this.removeItem.bind(this)}
          setTaxAndAccountsToDealSettings={this.setTaxAndAccountsToDealSettings.bind(this)}
          resetAccounts={this.resetAccounts.bind(this)}
          accounts={this.props.accounts}
          taxes={this.props.taxes}
          products={this.props.products}
          tracking={this.props.tracking}
          platformStyles={this.props.platformStyles}
          setError={this.props.setError}
          setSuccess={this.props.setSuccess}
          cancelInvoice={this.props.cancelInvoice}
          replaceInvoice={this.props.replaceInvoice}
          refreshInvoice={this.props.refreshInvoice}
        />
      } else {
        const error = this.props.invoiceErrors.find(e => !e.invoice.repeating && e.invoice.id === invoice.id);

        return <Invoice
          invoice={invoice}
          error={error || null}
          deal={this.props.deal}
          preferences={this.props.preferences}
          connectionData={this.props.connectionData}
          shortCode={this.props.connectionData?.shortCode}
          key={invoice.key}
          invoiceIndex={index}
          updateInvoice={this.props.updateInvoice}
          cloneInvoice={this.props.cloneInvoice}
          removeInvoice={this.props.removeInvoice}
          splitInvoice={this.props.splitInvoice}
          updateSetting={this.props.updateSetting}
          cloneItem={this.cloneItem.bind(this)}
          updateItem={this.updateItem.bind(this)}
          addItem={this.addItem.bind(this)}
          removeItem={this.removeItem.bind(this)}
          setTaxAndAccountsToDealSettings={this.setTaxAndAccountsToDealSettings.bind(this)}
          resetAccounts={this.resetAccounts.bind(this)}
          accounts={this.props.accounts}
          taxes={this.props.taxes}
          products={this.props.products}
          tracking={this.props.tracking}
          platformStyles={this.props.platformStyles}
          toggleEditContact={this.props.toggleEditContact}
          setError={this.props.setError}
          setSuccess={this.props.setSuccess}
          refreshInvoice={this.props.refreshInvoice}
        />
      }
    });

    invoiceNode.sort((a,b) => {
      const dateA = a.props.invoice.created ? DateTime.fromISO(a.props.invoice.created) : DateTime.now();
      const dateB = b.props.invoice.created ? DateTime.fromISO(b.props.invoice.created) : DateTime.now();

      return dateA - dateB;
    });
    
    return (
      <div className="p-5 mb-20 overflow-x-scroll mt-[7rem]">
        <ImportInvoice
          deal={this.props.deal}
          open={this.state.showImportInvoice}
          connectionType={this.props.connectionData?.connectionType}
          shortCode={this.props.connectionData?.shortCode}
          setClose={() => this.setState({ showImportInvoice: false })}
          setError={this.props.setError}
          setSuccess={this.props.setSuccess}
          platformStyles={this.props.platformStyles}
        />
        <div className="list">
          {!this.state?.showAddInvoices ? (
            <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>{invoiceNode}</DragDropContext>
          ) : null}
          {this.props.deal.settings.mode === "recurring" ||
          this.props.deal.settings.mode === "Monthly" ||
          this.props.deal.settings.mode === "deposit_recurring" ? (
            <div className="flex justify-center flex-grow">
              <button
                href="#"
                className="px-10 py-2 border-2 rounded-md text-platform border-platform"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.addRecurringInvoice();
                }}
              >
                Add Recurring Invoice
              </button>
            </div>
          ) : (
            <>
              <div
                className=" z-40 shadow-sm shadow-white fixed p-5 cursor-pointer text-white flex items-center justify-center rounded-full bottom-2.5 left-2.5 bg-platform"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showAddInvoices: !this.state?.showAddInvoices });
                }}
              >
                <PlusSmIcon className="w-8 h-8" />
              </div>
              <div className="flex flex-col justify-center gap-5 py-5 border-2 border-gray-200 border-dashed rounded-3xl">
                <div className="flex flex-row items-center gap-3 mx-auto">
                  <h3 className="font-semibold text-center text-grey">Add more invoices</h3>
                  {this.props.preferences?.importInvoices && (
                    <>
                      <span className="text-grey">or</span>
                      <button
                        className="flex justify-center text-sm underline text-platform"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ showImportInvoice: true });
                        }}
                      >
                        Import Existing Invoices
                      </button>
                      </>
                  )}
                </div>
                <NumberPicker
                  value={this.state.count}
                  updateHandler={(e) => (e > 0 ? this.setState({ count: e }) : null)}
                  handleKeypress={(e) => null}
                  className="relative flex items-center justify-center flex-1"
                />
                {this.props.connectionData?.plan !== "starter" &&
                this.props.preferences?.recurringBeta ? (
                  <div className="flex self-center justify-center flex-shrink bg-white">
                    <div className="relative self-center bg-gray-100 rounded-lg p-0.5 flex ">
                      <button
                        onClick={(e) => this.setState({ repeating: false })}
                        className={
                          (!this.state.repeating ? "bg-white" : "bg-transparent") +
                          " font-sans mr-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap sm:w-auto sm:px-8"
                        }
                      >
                        Standard Invoice{this.state.count > 1 ? "s" : null}
                      </button>
                      <button
                        onClick={(e) => this.setState({ repeating: true })}
                        className={
                          (this.state.repeating ? "bg-white" : "bg-transparent") +
                          " font-sans ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap sm:w-auto sm:px-8"
                        }
                      >
                        Repeating Invoice{this.state.count > 1 ? "s" : null}
                        {this.props.connectionData?.plan === "trial" ? (
                          <span className="relative px-3 py-1 text-xs font-semibold tracking-wide uppercase rounded-full -right-4 bg-palePrimary text-secondary">
                            Pro
                          </span>
                        ) : null}
                      </button>
                    </div>
                  </div>
                ) : null}

                <div className="flex justify-center flex-grow">
                  <button
                    href="#"
                    className="px-6 py-3 text-white border-2 rounded-md border-platform bg-platform"
                    onClick={(e) => {
                      this.setState({ showAddInvoices: false });
                      for (let i = 0; i < this.state.count; i++) {
                        this.props.addInvoice(this.state.repeating);
                      }
                    }}
                  >
                    Add more invoices
                  </button>
                  {this.state?.showAddInvoices ? (
                    <button
                      className="ml-5 text-sm underline text-platform"
                      onClick={(e) => {
                        this.setState({ showAddInvoices: false });
                      }}
                    >
                      Cancel
                    </button>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default InvoiceList;
