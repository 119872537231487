import React from "react";
import { ClockIcon, ExclamationIcon } from "@heroicons/react/outline";
import NumberDisplay from "./fields/NumberDisplay";
import { DateTime } from "luxon";

class LinkedInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <tr className={(this.props.key % 2 ? "bg-gray-200" : "") + " "}>
        <td className="py-2 pl-4 pr-3 text-xs font-medium text-gray-900 whitespace-nowrap sm:pl-6 md:pl-0">
          {this.props.invoice.external_id && this.props.shortCode ? (
            <a
              href={`https://go.xero.com/organisationlogin/default.aspx?shortcode=${this.props.shortCode}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${this.props.invoice.external_id}`}
              target="_blank"
              className="underline text-primary"
            >
              {this.props.invoice.external_invoicenumber}
            </a>
          ) : (
            <>{this.props.invoice.external_invoicenumber}</>
          )}
        </td>
        <td className="px-3 py-2 text-xs text-gray-500 whitespace-nowrap">
          {DateTime.fromISO(this.props.invoice.date).toLocaleString("DATE_SHORT")}
        </td>
        <td className="px-3 py-2 text-xs text-gray-500 whitespace-nowrap">
          <div className="flex gap-3">
            {DateTime.fromISO(this.props.invoice.due_date).toLocaleString("DATE_SHORT")}
            {this.props.invoice.overdue ? (
              <div className="flex items-center text-xs text-red-600">
                <ExclamationIcon className="w-5 mr-1"></ExclamationIcon>Overdue
              </div>
            ) : null}
          </div>
        </td>
        <td className="px-3 py-2 text-xs text-gray-500 capitalize whitespace-nowrap">
          {this.props.invoice.status.toLowerCase()}
          {this.props.invoice.sent ? ", Sent" : ", Not Sent"}
        </td>
        <td className="px-3 py-2 text-xs text-gray-500 whitespace-nowrap">
          <NumberDisplay
            value={this.props.invoice.total - (this.props.invoice?.creditExcTax ? Number(this.props.invoice.creditExcTax) : 0)}
            currency={this.props.invoice.currency}
            className="ml-1 text-sm"
          />
        </td>
        <td className="py-2 pl-4 pr-3 text-xs font-medium text-gray-900 whitespace-nowrap sm:pl-6 md:pl-0">
          <a
            href={this.props.pdfLink}
            target="_blank"
            className="underline text-primary"
          >
            Download PDF
          </a>
        </td>
      </tr>
    );
  }
}

export default LinkedInvoice;
